/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef, useEffect } from 'react'
import {useAnimations, useGLTF} from '@react-three/drei'

export default function Model(props) {
    const group = useRef()
    const refB12 = useRef()
    const { nodes, materials, animations } = useGLTF('/../../../../b12.gltf')

    useEffect(() => {
        if (window.innerWidth > 768) {
            document.getElementById("CanvasSection").addEventListener("mousemove", MouseMove);
        }
        return () => {
            document.getElementById("CanvasSection").removeEventListener("mousemove", MouseMove);
        }
    }, []);
    function MouseMove(e) {
        // group.current.rotation.x = 0 + e.clientY / window.innerHeight/2
        // group.current.rotation.y = -.5 + e.clientX / window.innerWidth
        // group.current.rotation.z = 0 + -e.clientY / window.innerWidth/2
    }
    //Animation setting
    const { actions, mixer  } = useAnimations(animations, group)
    useEffect(() => {
        for (const anim in actions) {
            actions[anim].setLoop(THREE.LoopOnce)
            console.log(actions[anim])
        }


    }, [mixer]);
    console.log(props.numCount)
    if (props.numCount === 3) actions.shake.play()
    return (
        <group ref={group}  {...props} dispose={null} rotation={[0,-.5,0]} position={[1, -.1, -2.5]}>
            <group name="Scene">
                <group scale={8}>
                    <group name="root">
                        <group name="GLTF_SceneRootNode">
                            <group name="Drone_StaticMesh_0">
                                <group name="B12" onClick={() => {
                                    actions.flip.play()
                                }} >
                                    <mesh name="Object_0" castShadow receiveShadow geometry={nodes.Object_0.geometry} material={materials.M_Drone} />
                                    <mesh name="Object_0_1"  receiveShadow geometry={nodes.Object_0_1.geometry} material={materials.M_eye_drone_Inst} />
                                </group>
                                <spotLight  intensity={1.9}  position={[1, 1, 0]} penumbra={1}/>
                                {/*<spotLight castShadow intensity={1.9} position={[-1, 1, 0]} penumbra={1}/>*/}
                            </group>
                        </group>
                    </group>
                </group>
            </group>
        </group>
    )

}


useGLTF.preload('/../../../../b12.gltf')
