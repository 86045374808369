import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { hydrate, render } from "react-dom";
import i18next from "i18next";

import global_en from "./lang/en/global.json"
import global_es from "./lang/es/global.json"
import {I18nextProvider} from "react-i18next";

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'en',
    resources: {
        en: {global: global_en},
        es: {global: global_es}
    }
})
const rootElement = document.getElementById("root");

    render(
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </BrowserRouter>,
        rootElement
    );
