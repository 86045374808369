/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'

export default function Model(props) {
    const { nodes, materials } = useGLTF('./robotInRuinScene.gltf')
    return (
        <group {...props} dispose={null}>
            <group position={[0, 0.5, -2.62]} scale={1.15}>
                <group position={[0, 2.06, 0.11]} rotation={[Math.PI, 0, -Math.PI]}>
                    <group position={[0, 0.14, 0]} rotation={[0.01, 0, 0]}>
                        <group position={[0, 0.17, 0]}>
                            <group position={[0, 0.19, 0]}>
                                <group position={[0, 0.21, 0]} rotation={[-0.01, 0, 0]}>
                                    <group position={[0, 0.17, 0.06]} />
                                </group>
                                <group position={[0.13, 0.18, 0]} rotation={[1.57, -0.27, -1.57]}>
                                    <group position={[0, 0.26, 0]} rotation={[0.83, 0.01, -0.01]}>
                                        <group position={[0, 0.43, 0]} rotation={[-0.09, 0, 0.02]}>
                                            <group position={[0, 0.52, 0]} rotation={[0.13, 0.01, 0.04]}>
                                                <group position={[0, 0.23, 0]} rotation={[0.15, -0.02, -0.14]}>
                                                    <group position={[0, 0.04, 0]}>
                                                        <group position={[0, 0.06, 0]} />
                                                    </group>
                                                </group>
                                            </group>
                                        </group>
                                    </group>
                                </group>
                                <group position={[-0.13, 0.18, 0]} rotation={[1.57, 0.27, 1.57]}>
                                    <group position={[0, 0.26, 0]} rotation={[0.83, -0.01, 0.01]}>
                                        <group position={[0, 0.43, 0]} rotation={[-0.09, 0, -0.02]}>
                                            <group position={[0, 0.52, 0]} rotation={[0.13, -0.01, -0.04]}>
                                                <group position={[0, 0.23, 0]} rotation={[0.16, 0.02, 0.13]}>
                                                    <group position={[0, 0.04, 0]}>
                                                        <group position={[0, 0.06, -0.01]} />
                                                    </group>
                                                </group>
                                            </group>
                                        </group>
                                    </group>
                                </group>
                            </group>
                        </group>
                    </group>
                    <group position={[0.27, -0.08, 0.01]} rotation={[0.01, 0, -3.13]}>
                        <group position={[0, 0.81, 0]} rotation={[0.03, 0, -0.01]}>
                            <group position={[0, 0.74, 0]} rotation={[1.04, -0.02, 0.04]}>
                                <group position={[0, 0.33, 0]} rotation={[0.5, -0.14, 0.07]} />
                            </group>
                        </group>
                    </group>
                    <group position={[-0.27, -0.08, 0.01]} rotation={[0.01, 0, 3.13]}>
                        <group position={[0, 0.81, 0]} rotation={[0.03, 0, 0.01]}>
                            <group position={[0, 0.74, 0]} rotation={[1.05, 0.02, -0.04]}>
                                <group position={[0, 0.34, 0]} rotation={[0.49, 0.14, -0.07]} />
                            </group>
                        </group>
                    </group>
                </group>
                <mesh castShadow receiveShadow geometry={nodes.robot002.geometry} material={materials.robot} />
            </group>
            {/*<directionalLight castShadow shadow-mapSize-height={1} shadow-bias={-0.000001} shadow-mapSize-width={1} intensity={.1} decay={2} color="#70ffff" position={[0.16, 9.89, -0.25]} rotation={[-1.52, -0.12, 0.66]} scale={1.64} />*/}
            <directionalLight castShadow shadow-mapSize={[1024,1024]} shadow-bias={-0.0000001} intensity={.2}  decay={2} color="#70ffff" position={[0.53, 10.03, .37]} rotation={[-0.09, 0, 0]} scale={1.64} />
            {/*<pointLight  castShadow shadow-mapSize-height={1} shadow-mapSize-width={1} intensity={.4} decay={2} color="#ff2a03" position={[-0.05, 7.73, 1.22]} rotation={[-Math.PI / 2, 0, 0]} />*/}
            <mesh castShadow receiveShadow geometry={nodes.Cube002.geometry} material={materials['Material.002']} position={[3.15, 0.13, 0.4]} rotation={[-0.18, -0.11, -0.48]} scale={[0.81, 0.44, 0.25]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube001.geometry} material={materials['Material.002']} position={[4.65, 0.34, 0.25]} rotation={[-0.18, -0.11, -0.48]} scale={0.06} />
            <mesh castShadow receiveShadow geometry={nodes.Cylinder000.geometry} material={materials['Material.002']} position={[3.29, 0.39, 0.37]} rotation={[-0.18, -0.11, -0.48]} scale={[0.32, 0.41, 0.32]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube000.geometry} material={materials['Material.002']} position={[3.2, 0.21, 0.38]} rotation={[-0.18, -0.11, -0.48]} scale={0.44} />
            <mesh castShadow receiveShadow geometry={nodes.Cube022.geometry} material={materials['Material.002']} position={[-5.95, 0.7, 1.31]} scale={0.44} />
            <mesh castShadow receiveShadow geometry={nodes.Cube019.geometry} material={materials['Material.002']} position={[7.07, 1.02, 0.47]} rotation={[-0.39, 0.15, -0.03]} scale={0.05} />
            <mesh castShadow receiveShadow geometry={nodes.Cube018.geometry} material={materials['Material.002']} position={[-0.06, 0.21, 0.16]} scale={[0.06, 0.13, 0.13]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube017.geometry} material={materials['Material.002']} position={[1.28, 0.41, 0.14]} rotation={[1.47, -0.3, 1.57]} scale={[0.03, 0.01, 0.01]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube016.geometry} material={materials['Material.002']} position={[-5.95, 0.6, 1.33]} scale={[0.81, 0.44, 0.25]} />
            <mesh castShadow receiveShadow geometry={nodes.Sphere001.geometry} material={materials['Material.002']} position={[-3.22, -0.29, 4.6]} scale={[0.45, 0.24, 0.24]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube014.geometry} material={materials['Material.002']} position={[-3.22, -0.6, 4.6]} scale={[0.51, 0.26, 0.3]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube013.geometry} material={materials['Material.002']} position={[0.18, 3.47, 0.08]} scale={[0.51, 0.26, 0.3]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube012.geometry} material={materials['Material.002']} position={[1.54, 0.81, -3.5]} scale={0.44} />
            <mesh castShadow receiveShadow geometry={nodes.Cube011.geometry} material={materials['Material.002']} position={[1.62, 0.36, 0.26]} rotation={[1.35, -0.65, 0.94]} scale={[0.06, 0.13, 0.13]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube243.geometry} material={materials['Material.006']} position={[12.45, 1.13, -11.24]} rotation={[-0.19, -0.09, -0.02]} scale={[0.06, 0.05, 0.06]} />
            <group position={[-11.05, 1.13, -12.05]} rotation={[2.29, -0.23, 2.66]} scale={[0, 0.14, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_9.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_10.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_11.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-8.63, 1.58, -11.37]} rotation={[-2.06, -0.15, -2.85]} scale={[0, 0.12, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_12.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_13.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_14.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-6.63, 1.34, -11.24]} rotation={[-2.42, -0.17, -2.54]} scale={[0, 0.14, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_15.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_16.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_17.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-7, 0.46, -13.08]} rotation={[-2.28, -0.47, -1.83]} scale={[0, 0.16, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_18.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_19.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_20.geometry} material={materials['Material.009']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Cube238.geometry} material={materials['Material.006']} position={[-3.29, 0.53, -11.77]} rotation={[-1.92, -1, -1.66]} scale={[0.24, 0.17, 0.24]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube237.geometry} material={materials['Material.007']} position={[-11.79, 0.53, 8.28]} rotation={[-0.69, -0.15, 0.11]} scale={[0.08, 0.06, 0.08]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube236.geometry} material={materials['Material.006']} position={[-11.51, 0.73, 3.43]} rotation={[2.37, -0.96, 2.14]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube235.geometry} material={materials['Material.007']} position={[-10.83, 1.01, 2.82]} rotation={[-0.74, -0.52, -0.53]} scale={[0.05, 0.04, 0.05]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube234.geometry} material={materials['Material.006']} position={[-12.64, 0.54, -9.31]} rotation={[1.82, -0.66, 1.97]} scale={[0.11, 0.08, 0.11]} />
            <group position={[-8.25, 0.4, 2.23]} rotation={[-1.1, -1.43, -1]} scale={[0.01, 0.3, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_21.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_22.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_23.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-9.04, 1.54, -10.51]} rotation={[0.11, -1.23, -0.1]} scale={[0.01, 0.26, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_24.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_25.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_26.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-8.96, 1.37, -9.98]} rotation={[0.94, -0.16, 0.24]} scale={[0, 0.11, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_27.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_28.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_29.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-7.64, 0.62, 11.58]} rotation={[2.37, -0.77, 2.51]} scale={[0.01, 0.25, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_30.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_31.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_32.geometry} material={materials['Material.009']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Cube229.geometry} material={materials['Material.006']} position={[-6.29, 0.73, 9.25]} rotation={[-1.28, -0.07, -0.74]} scale={[0.09, 0.07, 0.09]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube228.geometry} material={materials['Material.007']} position={[-7.5, 1.1, 6.75]} rotation={[2.89, -0.33, -3.12]} scale={[0.09, 0.07, 0.09]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube227.geometry} material={materials['Material.006']} position={[-6.12, 0.46, 1.74]} rotation={[-2.08, -0.93, -2.1]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube226.geometry} material={materials['Material.007']} position={[-8.12, 0.43, 2.45]} rotation={[-0.76, -0.45, -0.22]} scale={[0.05, 0.04, 0.05]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube225.geometry} material={materials['Material.006']} position={[-7.98, 0.64, 0.47]} rotation={[2.05, -0.22, 2.58]} scale={[0.08, 0.06, 0.08]} />
            <group position={[-6.98, 0.38, -6.87]} rotation={[-2.81, -0.85, -2.82]} scale={[0.01, 0.28, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_33.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_34.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_35.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-3.93, 0.2, -0.62]} rotation={[-1.25, -0.96, -1.18]} scale={[0.01, 0.28, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_36.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_37.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_38.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-4.92, 0.25, 0.56]} rotation={[0.59, -1.23, 0.59]} scale={[0.01, 0.26, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_39.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_40.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_41.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-3.57, 0.2, -4.5]} rotation={[0.57, -0.86, 0.45]} scale={[0.01, 0.2, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_42.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_43.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_44.geometry} material={materials['Material.009']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Cube220.geometry} material={materials['Material.006']} position={[-4.79, 0.23, -6.19]} rotation={[1.27, 0.05, -0.12]} scale={[0.08, 0.06, 0.08]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube219.geometry} material={materials['Material.007']} position={[-4.28, 0.68, -9.52]} rotation={[2.88, -0.96, 2.77]} scale={[0.14, 0.1, 0.14]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube218.geometry} material={materials['Material.006']} position={[-3.14, 0.2, -1.71]} rotation={[-0.95, -1.01, -0.87]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube217.geometry} material={materials['Material.007']} position={[-1.36, 0.26, -6.19]} rotation={[0.85, -1.24, 0.74]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube216.geometry} material={materials['Material.006']} position={[0.87, 0.2, 12.57]} rotation={[0.53, -0.07, 0.04]} scale={[0.05, 0.04, 0.05]} />
            <group position={[-0.78, 0.2, 8.8]} rotation={[2.41, -0.62, 2.66]} scale={[0.01, 0.24, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_45.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_46.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_47.geometry} material={materials['Material.009']} />
            </group>
            <group position={[0.14, 0.2, -3.83]} rotation={[-1.6, -0.54, -1.62]} scale={[0.01, 0.21, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_48.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_49.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_50.geometry} material={materials['Material.009']} />
            </group>
            <group position={[2.37, 0.2, 6.99]} rotation={[3.02, -1.45, 3.03]} scale={[0.01, 0.3, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_51.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_52.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_53.geometry} material={materials['Material.009']} />
            </group>
            <group position={[1.79, 0.2, 6.44]} rotation={[-0.18, -1.23, -0.17]} scale={[0.01, 0.26, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_54.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_55.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_56.geometry} material={materials['Material.009']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Cube211.geometry} material={materials['Material.006']} position={[3.14, 0.2, 5.6]} rotation={[0.66, -0.81, 0.52]} scale={[0.2, 0.14, 0.2]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube210.geometry} material={materials['Material.007']} position={[1.73, 0.2, 4.63]} rotation={[1.3, -0.91, 1.23]} scale={[0.15, 0.11, 0.15]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube209.geometry} material={materials['Material.006']} position={[2.22, 0.2, 2.58]} rotation={[0.62, -0.97, 0.53]} scale={[0.05, 0.04, 0.05]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube208.geometry} material={materials['Material.007']} position={[3.57, 0.2, -3.19]} rotation={[1.05, 0.25, -0.4]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube207.geometry} material={materials['Material.006']} position={[2.58, 0.2, -2.01]} rotation={[2.82, -0.74, 2.92]} scale={[0.11, 0.08, 0.11]} />
            <group position={[2.22, 0.2, -2.36]} rotation={[-1.39, -0.47, -1.18]} scale={[0.01, 0.2, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_57.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_58.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_59.geometry} material={materials['Material.009']} />
            </group>
            <group position={[5.67, 0.61, 2.8]} rotation={[2.41, -0.49, 2.52]} scale={[0, 0.17, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_60.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_61.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_62.geometry} material={materials['Material.009']} />
            </group>
            <group position={[5.36, 0.31, -1.16]} rotation={[-1.64, -1.08, -1.55]} scale={[0.01, 0.32, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_63.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_64.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_65.geometry} material={materials['Material.009']} />
            </group>
            <group position={[5.64, 0.31, -1.5]} rotation={[-0.1, -1.36, 0.03]} scale={[0.01, 0.32, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_66.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_67.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_68.geometry} material={materials['Material.009']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Cube202.geometry} material={materials['Material.006']} position={[8.08, 0.8, 9.89]} rotation={[0.39, -0.09, -0.02]} scale={[0.08, 0.06, 0.08]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube201.geometry} material={materials['Material.007']} position={[7.26, 1.05, 4.82]} rotation={[2.39, -0.41, 2.77]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube200.geometry} material={materials['Material.006']} position={[7.12, 0.57, -0.44]} rotation={[-1.89, -1.22, -1.73]} scale={[0.06, 0.05, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube199.geometry} material={materials['Material.007']} position={[9.58, 1.02, -0.2]} rotation={[-0.26, -0.31, 0.12]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube198.geometry} material={materials['Material.006']} position={[9.02, 0.32, -8.23]} rotation={[1.98, -0.34, 2.21]} scale={[0.09, 0.07, 0.09]} />
            <group position={[13.16, 0.24, 11.8]} rotation={[-2.31, -0.5, -2.63]} scale={[0, 0.2, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_69.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_70.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_71.geometry} material={materials['Material.009']} />
            </group>
            <group position={[12.58, 1.2, -3.62]} rotation={[-2.05, -1.34, -2.1]} scale={[0.01, 0.36, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_72.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_73.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_74.geometry} material={materials['Material.009']} />
            </group>
            <group position={[10.95, 1.11, -3.95]} rotation={[-0.15, 0.16, 0.27]} scale={[0, 0.1, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_75.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_76.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_77.geometry} material={materials['Material.009']} />
            </group>
            <group position={[11.02, 1.13, -7.07]} rotation={[2.42, -0.2, 2.56]} scale={[0, 0.19, 0]}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_78.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_79.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_80.geometry} material={materials['Material.009']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Cube193.geometry} material={materials['Material.007']} position={[1.82, 7.99, 11.93]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube192.geometry} material={materials['Material.006']} position={[-2, 8.14, 12.64]} rotation={[Math.PI, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube191.geometry} material={materials['Material.007']} position={[1.11, 8.45, 11.3]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube190.geometry} material={materials['Material.006']} position={[0.95, 7.99, 12.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube189.geometry} material={materials['Material.006']} position={[-0.18, 7.99, 12.15]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube188.geometry} material={materials['Material.007']} position={[-1.25, 8.45, 11.83]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube187.geometry} material={materials['Material.006']} position={[-2.9, 8.45, 11.93]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube186.geometry} material={materials['Material.007']} position={[9.26, 0.26, -0.49]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube185.geometry} material={materials['Material.006']} position={[8.29, 0.26, -10.67]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube184.geometry} material={materials['Material.006']} position={[8.03, 0.26, -6.66]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube183.geometry} material={materials['Material.007']} position={[6.9, 0.26, 8.95]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube182.geometry} material={materials['Material.006']} position={[5.93, 0.26, -8.31]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube181.geometry} material={materials['Material.007']} position={[5.67, 0.26, 0.42]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube180.geometry} material={materials['Material.006']} position={[4.54, 0.26, -0.49]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube179.geometry} material={materials['Material.006']} position={[5.93, 0.26, 1.12]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube178.geometry} material={materials['Material.007']} position={[5.67, 0.26, 5.14]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube177.geometry} material={materials['Material.006']} position={[4.54, 0.26, 6.59]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube176.geometry} material={materials['Material.007']} position={[5.93, 0.26, 8.2]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube175.geometry} material={materials['Material.006']} position={[5.67, 0.26, 12.21]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube174.geometry} material={materials['Material.006']} position={[4.54, 0.26, 11.31]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube173.geometry} material={materials['Material.007']} position={[3.57, 0.26, -8.31]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube172.geometry} material={materials['Material.006']} position={[3.31, 0.26, -1.94]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube171.geometry} material={materials['Material.007']} position={[2.18, 0.26, -0.49]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube170.geometry} material={materials['Material.006']} position={[3.57, 0.26, -1.24]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube169.geometry} material={materials['Material.006']} position={[3.31, 0.26, 0.42]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube168.geometry} material={materials['Material.007']} position={[2.18, 0.26, 1.87]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube167.geometry} material={materials['Material.006']} position={[3.57, 0.26, 3.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube166.geometry} material={materials['Material.007']} position={[3.31, 0.26, 5.14]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube165.geometry} material={materials['Material.006']} position={[-0.18, 0.26, -9.92]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube164.geometry} material={materials['Material.006']} position={[1.21, 0.26, -8.31]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube163.geometry} material={materials['Material.007']} position={[0.95, 0.26, -4.3]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube162.geometry} material={materials['Material.006']} position={[-0.18, 0.26, -2.85]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube161.geometry} material={materials['Material.007']} position={[1.21, 0.26, 3.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube160.geometry} material={materials['Material.006']} position={[0.95, 0.26, 9.86]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube159.geometry} material={materials['Material.006']} position={[-0.18, 0.26, 11.31]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube158.geometry} material={materials['Material.007']} position={[1.21, 0.26, 10.56]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube157.geometry} material={materials['Material.006']} position={[0.95, 0.26, 12.21]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube156.geometry} material={materials['Material.007']} position={[-2.54, 0.26, -9.92]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube155.geometry} material={materials['Material.006']} position={[-1.15, 0.26, -10.67]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube154.geometry} material={materials['Material.006']} position={[-1.41, 0.26, -6.66]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube153.geometry} material={materials['Material.007']} position={[-2.54, 0.26, -7.56]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube152.geometry} material={materials['Material.006']} position={[-1.15, 0.26, 1.12]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube151.geometry} material={materials['Material.007']} position={[-1.41, 0.26, 2.78]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube150.geometry} material={materials['Material.006']} position={[-2.54, 0.26, 1.87]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube149.geometry} material={materials['Material.006']} position={[-1.15, 0.26, 3.48]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube148.geometry} material={materials['Material.007']} position={[-1.41, 0.26, 9.86]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube147.geometry} material={materials['Material.006']} position={[-4.9, 0.26, -9.92]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube146.geometry} material={materials['Material.007']} position={[-3.51, 0.26, -5.95]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube145.geometry} material={materials['Material.006']} position={[-6.13, 0.26, -9.02]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube144.geometry} material={materials['Material.006']} position={[9.26, 0.26, -12.28]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.1, 0.07, 0.1]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube143.geometry} material={materials['Material.007']} position={[8.29, 0.26, -13.03]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.07, 0.05, 0.07]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube142.geometry} material={materials['Material.006']} position={[0.95, 0.26, -11.38]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.03, 0.02, 0.03]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube141.geometry} material={materials['Material.007']} position={[-2.54, 0.26, -12.28]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.04, 0.03, 0.04]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube140.geometry} material={materials['Material.006']} position={[-3.51, 0.26, -13.03]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.06, 0.04, 0.06]} />
            <mesh receiveShadow geometry={nodes.Plane003.geometry}  material={materials['Material.009']} position={[0.21, 0.26, -0.33]} scale={12.98}/>
            <mesh  receiveShadow geometry={nodes.Plane002.geometry} material={materials['Material.001']} position={[0.21, 0.26, -0.33]} scale={12.98} />
            <mesh geometry={nodes.Plane001.geometry} material={materials['Material.002']} position={[0.21, 0.2, -0.33]} scale={12.98} />
            <mesh receiveShadow geometry={nodes.Plane.geometry} material={materials['Material.001']} position={[0.21, 0.26, -0.33]} scale={12.98}>
                <mesh castShadow receiveShadow geometry={nodes.Cube024_2.geometry} material={materials['Material.006']} position={[-0.29, 0, -0.98]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_2.geometry} material={materials['Material.007']} position={[-0.21, 0, -0.92]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_1.geometry} material={materials['Material.006']} position={[0.06, 0, -0.85]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_4.geometry} material={materials['Material.007']} position={[0.62, 0, -0.98]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_4.geometry} material={materials['Material.006']} position={[0.7, 0, -0.92]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_3.geometry} material={materials['Material.006']} position={[-0.31, 0, 0.97]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_3.geometry} material={materials['Material.007']} position={[-0.29, 0, -0.61]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_2.geometry} material={materials['Material.006']} position={[-0.21, 0, 0.9]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_5.geometry} material={materials['Material.007']} position={[-0.12, 0, 0.79]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_5.geometry} material={materials['Material.006']} position={[-0.1, 0, 0.11]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_4.geometry} material={materials['Material.006']} position={[-0.21, 0, 0.17]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_4.geometry} material={materials['Material.007']} position={[-0.12, 0, 0.24]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_3.geometry} material={materials['Material.006']} position={[-0.1, 0, -0.07]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_6.geometry} material={materials['Material.007']} position={[-0.21, 0, -0.74]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_6.geometry} material={materials['Material.006']} position={[-0.12, 0, -0.67]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_5.geometry} material={materials['Material.006']} position={[-0.1, 0, -0.8]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_5.geometry} material={materials['Material.007']} position={[-0.03, 0, 0.9]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_4.geometry} material={materials['Material.006']} position={[0.06, 0, 0.79]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_7.geometry} material={materials['Material.007']} position={[0.08, 0, 0.66]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_7.geometry} material={materials['Material.006']} position={[-0.03, 0, 0.72]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_6.geometry} material={materials['Material.006']} position={[0.06, 0, 0.79]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_6.geometry} material={materials['Material.007']} position={[0.08, 0, 0.11]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_5.geometry} material={materials['Material.006']} position={[-0.03, 0, -0.38]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_8.geometry} material={materials['Material.007']} position={[0.06, 0, -0.49]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_8.geometry} material={materials['Material.006']} position={[0.08, 0, -0.8]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_7.geometry} material={materials['Material.006']} position={[0.15, 0, 0.9]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_7.geometry} material={materials['Material.007']} position={[0.24, 0, 0.24]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_6.geometry} material={materials['Material.006']} position={[0.26, 0, -0.07]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_9.geometry} material={materials['Material.007']} position={[0.15, 0, -0.01]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_9.geometry} material={materials['Material.006']} position={[0.24, 0, -0.12]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_8.geometry} material={materials['Material.006']} position={[0.26, 0, -0.25]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_8.geometry} material={materials['Material.007']} position={[0.15, 0, -0.19]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_7.geometry} material={materials['Material.006']} position={[0.24, 0, -0.31]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_10.geometry} material={materials['Material.007']} position={[0.44, 0, 0.84]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_10.geometry} material={materials['Material.006']} position={[0.33, 0, 0.72]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_9.geometry} material={materials['Material.006']} position={[0.42, 0, 0.6]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_9.geometry} material={materials['Material.007']} position={[0.44, 0, 0.29]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_8.geometry} material={materials['Material.006']} position={[0.33, 0, 0.17]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_11.geometry} material={materials['Material.007']} position={[0.42, 0, 0.06]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_11.geometry} material={materials['Material.006']} position={[0.44, 0, -0.25]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_10.geometry} material={materials['Material.006']} position={[0.33, 0, -0.19]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_10.geometry} material={materials['Material.007']} position={[0.42, 0, -0.12]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_9.geometry} material={materials['Material.006']} position={[0.44, 0, -0.8]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_12.geometry} material={materials['Material.007']} position={[0.52, 0, -0.19]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_12.geometry} material={materials['Material.006']} position={[0.78, 0, -0.31]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_11.geometry} material={materials['Material.006']} position={[0.8, 0, -0.61]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_11.geometry} material={materials['Material.007']} position={[0.52, 0.62, -1]} rotation={[0, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_10.geometry} material={materials['Material.006']} position={[-0.06, 0.63, 0.95]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_13.geometry} material={materials['Material.007']} position={[-0.09, 0.6, 0.94]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]} scale={[0.01, 0, 0.01]} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_13.geometry} material={materials['Material.006']} position={[0.03, 0.61, 0.89]} rotation={[0, 0, -Math.PI / 2]} scale={0.01} />
                <mesh castShadow receiveShadow geometry={nodes.Cube024_12.geometry} material={materials['Material.006']} position={[-0.06, 0.62, 0.89]} rotation={[0, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube023_12.geometry} material={materials['Material.007']} position={[0.07, 0.63, 0.9]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube021_11.geometry} material={materials['Material.006']} position={[-0.17, 0.61, 1]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={0} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_14.geometry} material={materials['Material.007']} position={[0.12, 0.6, 0.95]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.01, 0, 0.01]} />
            </mesh>
            <mesh castShadow receiveShadow geometry={nodes.Cube005.geometry} material={materials['tape.001']} position={[6.66, 4.32, 3.06]} scale={[0.83, 1.77, 0.83]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube.geometry} material={materials['tape.001']} position={[-0.18, 4.32, -6.84]} scale={[0.83, 1.77, 0.83]} />
            <group position={[-6.4, 1.43, 8.39]} rotation={[-0.18, 0.54, 0.34]} scale={[0.01, 0.52, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube018_1.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube018_2.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube018_3.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-5.55, 1.71, 8.39]} rotation={[0.06, 0.56, -0.12]} scale={[0.01, 0.52, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube017_1.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube017_2.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube017_3.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-5.94, 1.35, 8.73]} rotation={[0.55, 0.56, -0.12]} scale={[0.01, 0.52, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube016_1.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube016_2.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube016_3.geometry} material={materials['Material.009']} />
            </group>
            <group position={[-4.83, 1.83, 8.52]} rotation={[-0.33, 0.74, -0.17]} scale={[0.01, 0.52, 0.01]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube015_1.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_2.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube015_3.geometry} material={materials['Material.009']} />
            </group>
            <group position={[3.17, 0.87, 7.27]} rotation={[-0.33, 0.74, -0.17]} scale={[0.02, 0.68, 0.02]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube009.geometry} material={materials['Material.012']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube009_1.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube009_2.geometry} material={materials['Material.009']} />
            </group>
            <group position={[1.84, 0.78, 7.6]} rotation={[0.55, 0.56, -0.12]} scale={[0.02, 0.68, 0.02]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube008.geometry} material={materials['Material.011']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube008_1.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube008_2.geometry} material={materials['Material.009']} />
            </group>
            <group position={[2.35, 1, 7.15]} rotation={[0.06, 0.56, -0.12]} scale={[0.02, 0.68, 0.02]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube007_1.geometry} material={materials['Material.010']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_2.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube007_3.geometry} material={materials['Material.009']} />
            </group>
            <group position={[1.21, 0.94, 7.15]} rotation={[-0.18, 0.54, 0.34]} scale={[0.02, 0.68, 0.02]}>
                <mesh castShadow receiveShadow geometry={nodes.Cube006.geometry} material={materials['Material.004']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube006_1.geometry} material={materials['Material.005']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube006_2.geometry} material={materials['Material.009']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Cube024.geometry} material={materials['Material.006']} position={[-2.17, 0.24, 3.74]} scale={[0.29, 0.21, 0.29]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube023.geometry} material={materials['Material.007']} position={[-1.28, 0.5, 1.91]} scale={[0.19, 0.14, 0.19]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube021.geometry} material={materials['Material.006']} position={[-0.61, 0.23, 4.33]} scale={[0.16, 0.12, 0.16]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube015.geometry} material={materials['Material.007']} position={[-1.85, 0.42, 3]} rotation={[0.65, -0.79, 0.31]} scale={[0.36, 0.27, 0.36]} />
            <mesh castShadow receiveShadow geometry={nodes.Cube007.geometry} material={materials['Material.006']} position={[-0.39, 0.42, 3.05]} scale={[0.49, 0.36, 0.49]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere.geometry} material={materials['Material.008']} position={[-0.07, 5.65, 7.01]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere174.geometry} material={materials['Material.008']} position={[-10.12, 13.77, 10.34]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere173.geometry} material={materials['Material.008']} position={[-2.97, 13.79, 2.63]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere172.geometry} material={materials['Material.008']} position={[4.01, 13.8, -3.73]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere171.geometry} material={materials['Material.008']} position={[10.67, 13.81, 3.53]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere170.geometry} material={materials['Material.008']} position={[10.19, 13.82, -9.75]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere169.geometry} material={materials['Material.008']} position={[-8.3, 13.83, 5.34]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere168.geometry} material={materials['Material.008']} position={[7.63, 13.84, 2.05]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere167.geometry} material={materials['Material.008']} position={[11.84, 13.85, 10.99]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere166.geometry} material={materials['Material.008']} position={[-12.57, 13.85, 2.13]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere165.geometry} material={materials['Material.008']} position={[8.42, 13.86, 7.37]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere164.geometry} material={materials['Material.008']} position={[-9.61, 13.86, 0.12]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere163.geometry} material={materials['Material.008']} position={[-4.12, 13.86, -6.16]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere162.geometry} material={materials['Material.008']} position={[-11.92, 13.86, -6.45]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere161.geometry} material={materials['Material.008']} position={[8.16, 13.85, -4.9]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere160.geometry} material={materials['Material.008']} position={[9.18, 13.85, 3.07]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere159.geometry} material={materials['Material.008']} position={[10.18, 13.85, 3.33]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere158.geometry} material={materials['Material.008']} position={[6.02, 13.84, -0.3]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere157.geometry} material={materials['Material.008']} position={[-0.79, 13.83, -11.55]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere156.geometry} material={materials['Material.008']} position={[4.38, 13.82, -2.51]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere155.geometry} material={materials['Material.008']} position={[10.39, 13.81, -5.66]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere154.geometry} material={materials['Material.008']} position={[-4.49, 13.8, -5.85]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere153.geometry} material={materials['Material.008']} position={[-4.43, 13.78, -4.22]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere152.geometry} material={materials['Material.008']} position={[12.92, 13.77, -10.46]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere151.geometry} material={materials['Material.008']} position={[-8.71, 13.75, -4.98]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere150.geometry} material={materials['Material.008']} position={[-12.07, 13.73, 1.72]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere149.geometry} material={materials['Material.008']} position={[-11.75, 13.71, -10.54]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere148.geometry} material={materials['Material.008']} position={[-0.41, 13.69, 12.16]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere147.geometry} material={materials['Material.008']} position={[11.53, 13.67, 5.58]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere146.geometry} material={materials['Material.008']} position={[-4.09, 13.65, 4.47]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere145.geometry} material={materials['Material.008']} position={[4.44, 13.62, -5.78]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere144.geometry} material={materials['Material.008']} position={[-11.14, 13.59, 4.79]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere143.geometry} material={materials['Material.008']} position={[-2.32, 13.57, -1.13]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere142.geometry} material={materials['Material.008']} position={[8.98, 13.54, 9.2]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere141.geometry} material={materials['Material.008']} position={[-0.83, 13.5, -1.74]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere140.geometry} material={materials['Material.008']} position={[-10.43, 13.47, -6.15]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere139.geometry} material={materials['Material.008']} position={[6.83, 13.44, 4.4]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere138.geometry} material={materials['Material.008']} position={[-3.96, 13.4, 2.83]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere137.geometry} material={materials['Material.008']} position={[12.49, 13.37, 12.22]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere136.geometry} material={materials['Material.008']} position={[6.6, 13.33, -7.86]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere135.geometry} material={materials['Material.008']} position={[11.22, 13.29, 9.66]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere134.geometry} material={materials['Material.008']} position={[-11.25, 13.25, -10.34]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere133.geometry} material={materials['Material.008']} position={[8.84, 13.2, -2.24]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere132.geometry} material={materials['Material.008']} position={[-9.76, 13.16, -1.51]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere131.geometry} material={materials['Material.008']} position={[-2.96, 13.11, -10.45]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere130.geometry} material={materials['Material.008']} position={[-12.46, 13.07, 10.3]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere129.geometry} material={materials['Material.008']} position={[-12.97, 13.02, -3.52]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere128.geometry} material={materials['Material.008']} position={[-8.09, 12.97, -6.11]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere127.geometry} material={materials['Material.008']} position={[10.01, 12.92, 12.73]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere126.geometry} material={materials['Material.008']} position={[-0.58, 12.87, -2.56]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere125.geometry} material={materials['Material.008']} position={[5.63, 12.81, 11.96]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere124.geometry} material={materials['Material.008']} position={[-2.54, 12.76, 0.58]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere123.geometry} material={materials['Material.008']} position={[11.73, 12.7, 0.37]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere122.geometry} material={materials['Material.008']} position={[12.29, 12.64, 4.05]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere121.geometry} material={materials['Material.008']} position={[8.64, 12.58, -11.23]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere120.geometry} material={materials['Material.008']} position={[-11.73, 12.52, -4]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere119.geometry} material={materials['Material.008']} position={[-3.61, 12.46, 6.51]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere118.geometry} material={materials['Material.008']} position={[2.36, 12.39, 4.13]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere117.geometry} material={materials['Material.008']} position={[1.15, 12.33, -1.84]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere116.geometry} material={materials['Material.008']} position={[6.25, 12.26, 9.92]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere115.geometry} material={materials['Material.008']} position={[13.06, 12.19, -1.88]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere114.geometry} material={materials['Material.008']} position={[-9.27, 12.12, -1.31]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere113.geometry} material={materials['Material.008']} position={[0.98, 12.05, 7.56]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere112.geometry} material={materials['Material.008']} position={[-6.74, 11.98, -11.63]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere111.geometry} material={materials['Material.008']} position={[4.92, 11.9, -12.11]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere110.geometry} material={materials['Material.008']} position={[-4.61, 11.83, -6.67]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere109.geometry} material={materials['Material.008']} position={[3.03, 11.75, 9.24]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere108.geometry} material={materials['Material.008']} position={[-8.65, 11.67, -3.35]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere107.geometry} material={materials['Material.008']} position={[11.9, 11.59, 7.72]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere106.geometry} material={materials['Material.008']} position={[-8.37, 11.51, -0.9]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere105.geometry} material={materials['Material.008']} position={[-5.58, 11.43, 4.16]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere104.geometry} material={materials['Material.008']} position={[7.22, 11.34, -9.9]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere103.geometry} material={materials['Material.008']} position={[-6.43, 11.26, -10.81]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere102.geometry} material={materials['Material.008']} position={[-6.88, 11.17, -0.59]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere101.geometry} material={materials['Material.008']} position={[-2.3, 11.08, 11.95]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere100.geometry} material={materials['Material.008']} position={[-10.41, 10.99, 6.93]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere099.geometry} material={materials['Material.008']} position={[10.91, 10.9, 7.62]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere098.geometry} material={materials['Material.008']} position={[1.23, 10.81, 7.97]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere097.geometry} material={materials['Material.008']} position={[-0.23, 10.71, -9.4]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere096.geometry} material={materials['Material.008']} position={[0.59, 10.62, -7.36]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere095.geometry} material={materials['Material.008']} position={[8.55, 10.52, 5.73]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere094.geometry} material={materials['Material.008']} position={[9.42, 10.42, 7.16]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere093.geometry} material={materials['Material.008']} position={[12.38, 10.32, -5.76]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere092.geometry} material={materials['Material.008']} position={[-3.05, 10.22, 10.8]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere091.geometry} material={materials['Material.008']} position={[-2.12, 10.12, 7.05]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere090.geometry} material={materials['Material.008']} position={[-10.46, 10.01, 0.39]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere089.geometry} material={materials['Material.008']} position={[-7.56, 9.91, 4.11]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere088.geometry} material={materials['Material.008']} position={[5.11, 9.8, -8.43]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere087.geometry} material={materials['Material.008']} position={[-3.2, 9.69, -9.63]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere086.geometry} material={materials['Material.008']} position={[4.5, 9.58, -4.14]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere085.geometry} material={materials['Material.008']} position={[-9.47, 9.47, -12.14]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere084.geometry} material={materials['Material.008']} position={[0.02, 9.36, -10.22]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere083.geometry} material={materials['Material.008']} position={[-5.42, 9.24, -4.01]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere082.geometry} material={materials['Material.008']} position={[11.54, 9.13, 5.27]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere081.geometry} material={materials['Material.008']} position={[1.77, 9.01, -2.81]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere080.geometry} material={materials['Material.008']} position={[-8.43, 8.89, 2.37]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere079.geometry} material={materials['Material.008']} position={[-4.6, 8.77, 6.41]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere078.geometry} material={materials['Material.008']} position={[-2.49, 8.65, 7.12]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere077.geometry} material={materials['Material.008']} position={[10.15, 8.53, -4.84]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere076.geometry} material={materials['Material.008']} position={[5.32, 8.4, 6.28]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere075.geometry} material={materials['Material.008']} position={[5.94, 8.28, 7.88]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere074.geometry} material={materials['Material.008']} position={[-11.62, 8.15, -7.88]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere073.geometry} material={materials['Material.008']} position={[-9.7, 8.02, -4.78]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere072.geometry} material={materials['Material.008']} position={[-6.75, 7.89, 1.45]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere071.geometry} material={materials['Material.008']} position={[6.29, 7.76, -12.97]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere070.geometry} material={materials['Material.008']} position={[7.18, 7.63, 8.08]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere069.geometry} material={materials['Material.008']} position={[-1.2, 7.49, -2.97]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere068.geometry} material={materials['Material.008']} position={[-7.51, 7.36, 10.65]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere067.geometry} material={materials['Material.008']} position={[0.56, 7.22, -0.82]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere066.geometry} material={materials['Material.008']} position={[4.53, 7.08, -0.87]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere065.geometry} material={materials['Material.008']} position={[4.36, 6.94, -9.05]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere064.geometry} material={materials['Material.008']} position={[5.45, 6.8, 7.1]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere063.geometry} material={materials['Material.008']} position={[-9.84, 6.66, -13.36]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere062.geometry} material={materials['Material.008']} position={[3.28, 6.51, 9.65]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere061.geometry} material={materials['Material.008']} position={[-1.95, 6.37, -3.58]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere060.geometry} material={materials['Material.008']} position={[-10.55, 6.22, -4.51]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere059.geometry} material={materials['Material.008']} position={[-11.53, 6.07, 8.46]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere058.geometry} material={materials['Material.008']} position={[-0.75, 5.92, 8.07]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere057.geometry} material={materials['Material.008']} position={[-3.17, 5.77, 9.98]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere056.geometry} material={materials['Material.008']} position={[-5.28, 5.62, 11.72]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere055.geometry} material={materials['Material.008']} position={[1.68, 5.46, 2.09]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere054.geometry} material={materials['Material.008']} position={[-10.68, 5.31, -7.48]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere053.geometry} material={materials['Material.008']} position={[-12.6, 5.15, -1.14]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere052.geometry} material={materials['Material.008']} position={[11.46, 4.99, 8.85]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere051.geometry} material={materials['Material.008']} position={[7.71, 4.83, -9.39]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere050.geometry} material={materials['Material.008']} position={[-3.9, 4.67, -0.44]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere049.geometry} material={materials['Material.008']} position={[-9.41, 4.5, -10.5]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere048.geometry} material={materials['Material.008']} position={[-9.59, 4.34, -12.95]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere047.geometry} material={materials['Material.008']} position={[7.29, 4.17, -6.63]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere046.geometry} material={materials['Material.008']} position={[-1.19, 4.01, 10.11]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere045.geometry} material={materials['Material.008']} position={[-3.79, 3.84, 11.41]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere044.geometry} material={materials['Material.008']} position={[-9.99, 3.67, 11.16]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere043.geometry} material={materials['Material.008']} position={[-6.4, 3.5, 8.81]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere042.geometry} material={materials['Material.008']} position={[-7.76, 3.32, 11.47]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere041.geometry} material={materials['Material.008']} position={[-12.01, 3.15, 3.36]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere040.geometry} material={materials['Material.008']} position={[10.04, 2.97, 6.19]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere039.geometry} material={materials['Material.008']} position={[-1.79, 2.79, 1.73]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere038.geometry} material={materials['Material.008']} position={[-12.26, 2.62, 2.95]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere037.geometry} material={materials['Material.008']} position={[11.17, 2.44, 3.13]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere035.geometry} material={materials['Material.008']} position={[-0.3, 2.07, 2.04]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere034.geometry} material={materials['Material.008']} position={[11.34, 1.89, 10.48]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere033.geometry} material={materials['Material.008']} position={[-10.23, 1.7, 2.17]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere032.geometry} material={materials['Material.008']} position={[9.43, 1.51, 2.26]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere031.geometry} material={materials['Material.008']} position={[1, 1.32, 2.65]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere030.geometry} material={materials['Material.008']} position={[-5.92, 1.13, -3.61]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere029.geometry} material={materials['Material.008']} position={[8.35, 0.94, -2.44]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere028.geometry} material={materials['Material.008']} position={[10.29, 0.75, 6.6]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere027.geometry} material={materials['Material.008']} position={[1.99, 0.55, 2.91]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere026.geometry} material={materials['Material.008']} position={[7.87, 0.36, 11.04]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere025.geometry} material={materials['Material.008']} position={[-6.72, 0.16, -5.09]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere024.geometry} material={materials['Material.008']} position={[7.15, -0.04, -11.54]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere023.geometry} material={materials['Material.008']} position={[11.45, -0.24, -4.23]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere022.geometry} material={materials['Material.008']} position={[-8.54, -0.44, -9.07]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere021.geometry} material={materials['Material.008']} position={[13.12, -0.64, -5.15]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere020.geometry} material={materials['Material.008']} position={[-6.09, -0.85, 7.02]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere019.geometry} material={materials['Material.008']} position={[-6.27, -1.05, 11.93]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere018.geometry} material={materials['Material.008']} position={[-11.58, -1.26, 1.92]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere017.geometry} material={materials['Material.008']} position={[3.29, -1.47, -0.16]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere016.geometry} material={materials['Material.008']} position={[6.7, -1.68, 3.59]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere015.geometry} material={materials['Material.008']} position={[1.9, -1.89, -2]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere014.geometry} material={materials['Material.008']} position={[12.5, -2.11, -7.4]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere013.geometry} material={materials['Material.008']} position={[-10.1, -2.32, 0.53]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere012.geometry} material={materials['Material.008']} position={[2.41, -2.54, 10.67]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere011.geometry} material={materials['Material.008']} position={[4.32, -2.76, -6.59]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere010.geometry} material={materials['Material.008']} position={[-5.73, -2.97, -4.83]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere009.geometry} material={materials['Material.008']} position={[1.31, -3.19, -0.21]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere008.geometry} material={materials['Material.008']} position={[-3.14, -3.42, -12.9]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere007.geometry} material={materials['Material.008']} position={[-4.03, -3.64, 1.2]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere006.geometry} material={materials['Material.008']} position={[-3.42, -3.86, 8.96]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere005.geometry} material={materials['Material.008']} position={[-7.1, -4.09, -6.31]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere004.geometry} material={materials['Material.008']} position={[-6.8, -4.32, -8.36]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere003.geometry} material={materials['Material.008']} position={[-10.71, -4.55, -0.94]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere002.geometry} material={materials['Material.008']} position={[-10.06, -4.78, 7.07]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere001.geometry} material={materials['Material.008']} position={[6.69, -5.01, 8.49]} rotation={[0, -1.57, 0]} scale={0.02} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere348.geometry} material={materials['Material.008']} position={[6.69, -0.17, 8.49]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere347.geometry} material={materials['Material.008']} position={[-10.06, -0.02, 7.07]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere346.geometry} material={materials['Material.008']} position={[-10.71, 0.12, -0.94]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere345.geometry} material={materials['Material.008']} position={[-6.8, 0.27, -8.36]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere344.geometry} material={materials['Material.008']} position={[-7.1, 0.41, -6.31]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere343.geometry} material={materials['Material.008']} position={[-3.42, 0.55, 8.96]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere342.geometry} material={materials['Material.008']} position={[-4.03, 0.69, 1.2]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere341.geometry} material={materials['Material.008']} position={[-3.14, 0.83, -12.9]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere340.geometry} material={materials['Material.008']} position={[1.31, 0.97, -0.21]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere339.geometry} material={materials['Material.008']} position={[-5.73, 1.11, -4.83]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere338.geometry} material={materials['Material.008']} position={[4.32, 1.24, -6.59]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere337.geometry} material={materials['Material.008']} position={[2.41, 1.38, 10.67]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere336.geometry} material={materials['Material.008']} position={[-10.1, 1.52, 0.53]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere335.geometry} material={materials['Material.008']} position={[12.5, 1.65, -7.4]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere334.geometry} material={materials['Material.008']} position={[1.9, 1.78, -2]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere333.geometry} material={materials['Material.008']} position={[6.7, 1.92, 3.59]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere332.geometry} material={materials['Material.008']} position={[3.29, 2.05, -0.16]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere331.geometry} material={materials['Material.008']} position={[-11.58, 2.18, 1.92]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere330.geometry} material={materials['Material.008']} position={[-6.27, 2.31, 11.93]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere329.geometry} material={materials['Material.008']} position={[-6.09, 2.44, 7.02]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere328.geometry} material={materials['Material.008']} position={[13.12, 2.57, -5.15]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere327.geometry} material={materials['Material.008']} position={[-8.54, 2.69, -9.07]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere326.geometry} material={materials['Material.008']} position={[11.45, 2.82, -4.23]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere325.geometry} material={materials['Material.008']} position={[7.15, 2.95, -11.54]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere324.geometry} material={materials['Material.008']} position={[-6.72, 3.07, -5.09]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere323.geometry} material={materials['Material.008']} position={[7.87, 3.19, 11.04]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere322.geometry} material={materials['Material.008']} position={[1.99, 3.32, 2.91]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere321.geometry} material={materials['Material.008']} position={[10.29, 3.44, 6.6]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow name={'targetObject'} geometry={nodes.Icosphere320.geometry} material={materials['Material.008']} position={[8.35, 3.56, -2.44]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere319.geometry} material={materials['Material.008']} position={[-5.92, 3.68, -3.61]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere318.geometry} material={materials['Material.008']} position={[1, 3.8, 2.65]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere317.geometry} material={materials['Material.008']} position={[9.43, 3.92, 2.26]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere316.geometry} material={materials['Material.008']} position={[-10.23, 4.03, 2.17]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere315.geometry} material={materials['Material.008']} position={[11.34, 4.15, 10.48]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere314.geometry} material={materials['Material.008']} position={[-0.3, 4.27, 2.04]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere313.geometry} material={materials['Material.008']} position={[2.32, 4.38, -13.03]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere312.geometry} material={materials['Material.008']} position={[11.17, 4.49, 3.13]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere311.geometry} material={materials['Material.008']} position={[-12.26, 4.61, 2.95]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere310.geometry} material={materials['Material.008']} position={[-1.79, 4.72, 1.73]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere309.geometry} material={materials['Material.008']} position={[10.04, 4.83, 6.19]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere308.geometry} material={materials['Material.008']} position={[-12.01, 4.94, 3.36]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere307.geometry} material={materials['Material.008']} position={[-7.76, 5.05, 11.47]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere306.geometry} material={materials['Material.008']} position={[-6.4, 5.16, 8.81]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere305.geometry} material={materials['Material.008']} position={[-9.99, 5.27, 11.16]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere304.geometry} material={materials['Material.008']} position={[-3.79, 5.37, 11.41]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere303.geometry} material={materials['Material.008']} position={[-1.19, 5.48, 10.11]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere302.geometry} material={materials['Material.008']} position={[7.29, 5.58, -6.63]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere301.geometry} material={materials['Material.008']} position={[-9.59, 5.69, -12.95]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere300.geometry} material={materials['Material.008']} position={[-9.41, 5.79, -10.5]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere299.geometry} material={materials['Material.008']} position={[-3.9, 5.89, -0.44]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere298.geometry} material={materials['Material.008']} position={[7.71, 5.99, -9.39]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere297.geometry} material={materials['Material.008']} position={[11.46, 6.09, 8.85]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere296.geometry} material={materials['Material.008']} position={[-12.6, 6.19, -1.14]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere295.geometry} material={materials['Material.008']} position={[-10.68, 6.29, -7.48]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere294.geometry} material={materials['Material.008']} position={[1.68, 6.39, 2.09]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere293.geometry} material={materials['Material.008']} position={[-5.28, 6.48, 11.72]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere292.geometry} material={materials['Material.008']} position={[-3.17, 6.58, 9.98]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere291.geometry} material={materials['Material.008']} position={[-0.75, 6.68, 8.07]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere290.geometry} material={materials['Material.008']} position={[-11.53, 6.77, 8.46]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere289.geometry} material={materials['Material.008']} position={[-10.55, 6.86, -4.51]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere288.geometry} material={materials['Material.008']} position={[-1.95, 6.95, -3.58]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere287.geometry} material={materials['Material.008']} position={[3.28, 7.05, 9.65]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere286.geometry} material={materials['Material.008']} position={[-9.84, 7.14, -13.36]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere285.geometry} material={materials['Material.008']} position={[5.45, 7.23, 7.1]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere284.geometry} material={materials['Material.008']} position={[4.36, 7.31, -9.05]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere283.geometry} material={materials['Material.008']} position={[4.53, 7.4, -0.87]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere282.geometry} material={materials['Material.008']} position={[0.56, 7.49, -0.82]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere281.geometry} material={materials['Material.008']} position={[-7.51, 7.58, 10.65]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere280.geometry} material={materials['Material.008']} position={[-1.2, 7.66, -2.97]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere279.geometry} material={materials['Material.008']} position={[7.18, 7.74, 8.08]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere278.geometry} material={materials['Material.008']} position={[6.29, 7.83, -12.97]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere277.geometry} material={materials['Material.008']} position={[-6.75, 7.91, 1.45]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere276.geometry} material={materials['Material.008']} position={[-9.7, 7.99, -4.78]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere275.geometry} material={materials['Material.008']} position={[-11.62, 8.07, -7.88]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere274.geometry} material={materials['Material.008']} position={[5.94, 8.15, 7.88]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere273.geometry} material={materials['Material.008']} position={[5.32, 8.23, 6.28]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere272.geometry} material={materials['Material.008']} position={[10.15, 8.31, -4.84]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere271.geometry} material={materials['Material.008']} position={[-2.49, 8.38, 7.12]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere270.geometry} material={materials['Material.008']} position={[-4.6, 8.46, 6.41]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere269.geometry} material={materials['Material.008']} position={[-8.43, 8.54, 2.37]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere268.geometry} material={materials['Material.008']} position={[1.77, 8.61, -2.81]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere267.geometry} material={materials['Material.008']} position={[11.54, 8.68, 5.27]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere266.geometry} material={materials['Material.008']} position={[-5.42, 8.76, -4.01]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere265.geometry} material={materials['Material.008']} position={[0.02, 8.83, -10.22]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere264.geometry} material={materials['Material.008']} position={[-9.47, 8.9, -12.14]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere263.geometry} material={materials['Material.008']} position={[4.5, 8.97, -4.14]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere262.geometry} material={materials['Material.008']} position={[-3.2, 9.04, -9.63]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere261.geometry} material={materials['Material.008']} position={[5.11, 9.11, -8.43]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere260.geometry} material={materials['Material.008']} position={[-7.56, 9.17, 4.11]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere259.geometry} material={materials['Material.008']} position={[-10.46, 9.24, 0.39]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere258.geometry} material={materials['Material.008']} position={[-2.12, 9.3, 7.05]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere257.geometry} material={materials['Material.008']} position={[-3.05, 9.37, 10.8]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere256.geometry} material={materials['Material.008']} position={[12.38, 9.43, -5.76]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere255.geometry} material={materials['Material.008']} position={[9.42, 9.49, 7.16]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere254.geometry} material={materials['Material.008']} position={[8.55, 9.56, 5.73]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere253.geometry} material={materials['Material.008']} position={[0.59, 9.62, -7.36]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere252.geometry} material={materials['Material.008']} position={[-0.23, 9.68, -9.4]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere251.geometry} material={materials['Material.008']} position={[1.23, 9.74, 7.97]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere250.geometry} material={materials['Material.008']} position={[10.91, 9.79, 7.62]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere249.geometry} material={materials['Material.008']} position={[-10.41, 9.85, 6.93]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere248.geometry} material={materials['Material.008']} position={[-2.3, 9.91, 11.95]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere247.geometry} material={materials['Material.008']} position={[-6.88, 9.96, -0.59]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere246.geometry} material={materials['Material.008']} position={[-6.43, 10.02, -10.81]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere245.geometry} material={materials['Material.008']} position={[7.22, 10.07, -9.9]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere244.geometry} material={materials['Material.008']} position={[-5.58, 10.12, 4.16]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere243.geometry} material={materials['Material.008']} position={[-8.37, 10.17, -0.9]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere242.geometry} material={materials['Material.008']} position={[11.9, 10.23, 7.72]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere241.geometry} material={materials['Material.008']} position={[-8.65, 10.28, -3.35]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere240.geometry} material={materials['Material.008']} position={[3.03, 10.33, 9.24]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere239.geometry} material={materials['Material.008']} position={[-4.61, 10.37, -6.67]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere238.geometry} material={materials['Material.008']} position={[4.92, 10.42, -12.11]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere237.geometry} material={materials['Material.008']} position={[-6.74, 10.47, -11.63]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere236.geometry} material={materials['Material.008']} position={[0.98, 10.51, 7.56]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere235.geometry} material={materials['Material.008']} position={[-9.27, 10.56, -1.31]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere234.geometry} material={materials['Material.008']} position={[13.06, 10.6, -1.88]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere233.geometry} material={materials['Material.008']} position={[6.25, 10.64, 9.92]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere232.geometry} material={materials['Material.008']} position={[1.15, 10.69, -1.84]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere231.geometry} material={materials['Material.008']} position={[2.36, 10.73, 4.13]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere230.geometry} material={materials['Material.008']} position={[-3.61, 10.77, 6.51]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere229.geometry} material={materials['Material.008']} position={[-11.73, 10.81, -4]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere228.geometry} material={materials['Material.008']} position={[8.64, 10.84, -11.23]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere227.geometry} material={materials['Material.008']} position={[12.29, 10.88, 4.05]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere226.geometry} material={materials['Material.008']} position={[11.73, 10.92, 0.37]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere225.geometry} material={materials['Material.008']} position={[-2.54, 10.95, 0.58]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere224.geometry} material={materials['Material.008']} position={[5.63, 10.99, 11.96]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere223.geometry} material={materials['Material.008']} position={[-0.58, 11.02, -2.56]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere222.geometry} material={materials['Material.008']} position={[10.01, 11.06, 12.73]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere221.geometry} material={materials['Material.008']} position={[-8.09, 11.09, -6.11]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere220.geometry} material={materials['Material.008']} position={[-12.97, 11.12, -3.52]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere219.geometry} material={materials['Material.008']} position={[-12.46, 11.15, 10.3]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere218.geometry} material={materials['Material.008']} position={[-2.96, 11.18, -10.45]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere217.geometry} material={materials['Material.008']} position={[-9.76, 11.21, -1.51]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere216.geometry} material={materials['Material.008']} position={[8.84, 11.23, -2.24]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere215.geometry} material={materials['Material.008']} position={[-11.25, 11.26, -10.34]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere214.geometry} material={materials['Material.008']} position={[11.22, 11.29, 9.66]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere213.geometry} material={materials['Material.008']} position={[6.6, 11.31, -7.86]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere212.geometry} material={materials['Material.008']} position={[12.49, 11.34, 12.22]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere211.geometry} material={materials['Material.008']} position={[-3.96, 11.36, 2.83]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere210.geometry} material={materials['Material.008']} position={[6.83, 11.38, 4.4]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere209.geometry} material={materials['Material.008']} position={[-10.43, 11.4, -6.15]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere208.geometry} material={materials['Material.008']} position={[-0.83, 11.42, -1.74]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere207.geometry} material={materials['Material.008']} position={[8.98, 11.44, 9.2]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere206.geometry} material={materials['Material.008']} position={[-2.32, 11.46, -1.13]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere205.geometry} material={materials['Material.008']} position={[-11.14, 11.48, 4.79]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere204.geometry} material={materials['Material.008']} position={[4.44, 11.5, -5.78]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere203.geometry} material={materials['Material.008']} position={[-4.09, 11.51, 4.47]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere202.geometry} material={materials['Material.008']} position={[11.53, 11.53, 5.58]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere201.geometry} material={materials['Material.008']} position={[-0.41, 11.54, 12.16]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere200.geometry} material={materials['Material.008']} position={[-11.75, 11.55, -10.54]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere199.geometry} material={materials['Material.008']} position={[-12.07, 11.57, 1.72]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere198.geometry} material={materials['Material.008']} position={[-8.71, 11.58, -4.98]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere197.geometry} material={materials['Material.008']} position={[12.92, 11.59, -10.46]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere196.geometry} material={materials['Material.008']} position={[-4.43, 11.6, -4.22]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere195.geometry} material={materials['Material.008']} position={[-4.49, 11.61, -5.85]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere194.geometry} material={materials['Material.008']} position={[10.39, 11.61, -5.66]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere193.geometry} material={materials['Material.008']} position={[4.38, 11.62, -2.51]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere192.geometry} material={materials['Material.008']} position={[-0.79, 11.63, -11.55]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere191.geometry} material={materials['Material.008']} position={[6.02, 11.63, -0.3]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere190.geometry} material={materials['Material.008']} position={[10.18, 11.64, 3.33]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere189.geometry} material={materials['Material.008']} position={[9.18, 11.64, 3.07]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere188.geometry} material={materials['Material.008']} position={[8.16, 11.64, -4.9]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere187.geometry} material={materials['Material.008']} position={[-11.92, 11.64, -6.45]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere186.geometry} material={materials['Material.008']} position={[-4.12, 11.64, -6.16]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere185.geometry} material={materials['Material.008']} position={[-9.61, 11.64, 0.12]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere184.geometry} material={materials['Material.008']} position={[8.42, 11.64, 7.37]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere183.geometry} material={materials['Material.008']} position={[-12.57, 11.64, 2.13]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere182.geometry} material={materials['Material.008']} position={[11.84, 11.64, 10.99]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere181.geometry} material={materials['Material.008']} position={[7.63, 11.63, 2.05]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere180.geometry} material={materials['Material.008']} position={[-8.3, 11.63, 5.34]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere179.geometry} material={materials['Material.008']} position={[10.19, 11.62, -9.75]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere178.geometry} material={materials['Material.008']} position={[10.67, 11.62, 3.53]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere177.geometry} material={materials['Material.008']} position={[4.01, 11.61, -3.73]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere176.geometry} material={materials['Material.008']} position={[-2.97, 11.6, 2.63]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <mesh castShadow receiveShadow geometry={nodes.Icosphere175.geometry} material={materials['Material.008']} position={[-10.12, 11.59, 10.34]} rotation={[0, -1.57, 0]} scale={[0.02, 0.01, 0.02]} />
            <group position={[4.42, 0.31, 3.9]} rotation={[-1.44, -0.46, -1.34]} scale={0.06}>
                <mesh castShadow receiveShadow geometry={nodes.Cube046.geometry} material={materials['Material.014']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube046_1.geometry} material={materials['Material.017']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Circle002.geometry} material={materials['Material.002']} position={[4.67, 0.44, 3.86]} rotation={[-1.44, -0.46, -1.34]} scale={0.06} />
            <mesh castShadow receiveShadow geometry={nodes.Cube040.geometry} material={materials['Material.016']} position={[4.56, 0.38, 3.88]} rotation={[-1.44, -0.46, -1.34]} scale={0.06} />
            <mesh castShadow receiveShadow geometry={nodes.Cube039.geometry} material={materials['Material.006']} position={[4.5, 0.35, 3.89]} rotation={[-1.44, -0.46, -1.34]} scale={0.06} />
            <group position={[-4.64, 0.36, 2.81]} rotation={[-2.63, -0.99, -2.77]} scale={0.05}>
                <mesh castShadow receiveShadow geometry={nodes.Cube030_1.geometry} material={materials['Material.013']} />
                <mesh castShadow receiveShadow geometry={nodes.Cube030_2.geometry} material={materials['Material.005']} />
            </group>
            <mesh castShadow receiveShadow geometry={nodes.Circle.geometry} material={materials['Material.002']} position={[-4.55, 0.58, 2.91]} rotation={[-2.22, -0.8, -2.56]} scale={0.05} />
            <mesh castShadow receiveShadow geometry={nodes.Cube034.geometry} material={materials['Material.005']} position={[-4.59, 0.5, 2.86]} rotation={[-2.22, -0.8, -2.56]} scale={0.05} />
            <mesh castShadow receiveShadow geometry={nodes.Cube033.geometry} material={materials['Material.006']} position={[-4.62, 0.44, 2.83]} rotation={[-2.63, -0.99, -2.77]} scale={0.05} />
            <group position={[0, 0.53, 1.13]} scale={0.27}>
                <mesh castShadow receiveShadow geometry={nodes.Mesh_85.geometry} material={materials['Material.019']} />
                <mesh castShadow receiveShadow geometry={nodes.Mesh_86.geometry} material={materials['Material.001']} />
            </group>
        </group>
    )
}

useGLTF.preload('./robotInRuinScene.gltf')
